import React, { useEffect } from "react"

import PropTypes from "prop-types"

import CheckIcon from "../../assets/images/Icons/check.svg"
import LogoIcon from "../../assets/images/Logo.svg"
import { AuthenticationContext, ApplicationContext } from "../../context/"
import AutoLogout from "../Application/AutoLogout"
import "./WashingConfirmLayout.scss"

const WASHING_PICKUP = "WASHING_PICKUP"
const WASHING_DROP_OFF = "WASHING_DROP_OFF"

const WashingConfirmLayout = ({ type }) => {
  const { logOut, user, isLoadingRequest } = React.useContext(
    AuthenticationContext
  )
  const { resetOrders } = React.useContext(ApplicationContext)

  useEffect(() => {
    resetOrders()
  }, [])

  const Message = () => {
    if (type === WASHING_PICKUP) {
      return (
        <p className="layout__message">
          We have confirmed your order. Please collect your washing.
          <br /> <br />
          You will be emailed to confirm collection was successful.
        </p>
      )
    } else if (type === WASHING_DROP_OFF) {
      return (
        <p className="layout__message">
          We have confirmed your order.
          {user.mobile && (
            <span>
              You have been sent a text message to <br /> {user.mobile}
              <br /> <br />
              Please confirm the drop off was successful.
            </span>
          )}
        </p>
      )
    }
  }

  WashingConfirmLayout.propTypes = {
    type: PropTypes.string.isRequired,
  }
  return (
    <div className="washing-confirm-layout">
      <div className="washing-confirm-layout__logo">
        <img src={LogoIcon}></img>
        <div className="washing-confirm-layout__logo__text">
          <p>Self service laundromat</p>
        </div>
      </div>
      <div className="washing-confirm-layout__container">
        <div className="washing-confirm-layout__body">
          <div className="washing-confirm-layout__body__icon">
            <img src={CheckIcon} />
          </div>
          <div className="washing-confirm-layout__body__message">
            <Message />
          </div>
          <div className="washing-confirm-layout__body__sub-message">
            <p>
              Thank you for visiting your neighbourhood washroom, we hope you
              have been happy with your service
            </p>
          </div>
          <button
            className="washing-confirm-layout__body__logout"
            disabled={isLoadingRequest}
            onClick={() => logOut()}
          >
            Logout {"&"} Head Home
          </button>
          <div className="email-link">
            <a href="mailto:hello@wrlaundry.com">hello@wrlaundry.com</a>
          </div>
        </div>
      </div>
      <div className="washing-confirm-layout__footer">
        <AutoLogout></AutoLogout>
      </div>
    </div>
  )
}

export default WashingConfirmLayout
