import React from "react"

import { AuthenticationContext } from "../../context/"
import "./AutoLogout.scss"

const AutoLogout = () => {
  const [logoutTime, setLogoutTime] = React.useState(5)

  const { autoLogout } = React.useContext(AuthenticationContext)

  React.useEffect(() => {
    const controlLogoutTime = () => {
      setLogoutTime(logoutTime - 1)
    }

    if (logoutTime > 0) setTimeout(controlLogoutTime, 1000)
  }, [logoutTime])

  React.useEffect(() => {
    autoLogout()
  }, [])

  return (
    <div className="auto-logout">
      <p className="auto-logout__text">Auto Logout — {logoutTime}</p>
    </div>
  )
}

export default AutoLogout
