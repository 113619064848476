import React from "react"

import ProtectedRouteLayout from "../components/Layout/ProtectedRouteLayout"
import WashingConfirmLayout from "../components/Layout/WashingConfirmLayout"

const WASHING_PICKUP = "WASHING_PICKUP"

const pickUpConfirm = () => {
  return (
    <ProtectedRouteLayout>
      <WashingConfirmLayout type={WASHING_PICKUP} />
    </ProtectedRouteLayout>
  )
}

export default pickUpConfirm
